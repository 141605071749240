import { Button } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { ReactComponent as WhiteAddGlossaryIcon } from "../../assets/whiteAddGlossaryIcon.svg";
import { ReactComponent as AddGlossaryIcon } from "../../assets/addGlossaryIcon.svg";

import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as GlossarySearchIcon } from "../../assets/glossarySearchIcon.svg";
import GlossaryAccordionComponent from "../AccordionGlossary/GlossaryAccordionComponent";
import { useState } from "react";
import GlossaryFooter from "../GlossaryFooter/GlossaryFooter";
import { clearFindResults } from "../../utils/glossaryUtils";
import GlossaryApplicationStatus from "../GlossaryApplicationStatus/GlossaryApplicationStatus";
import { useSelector } from "react-redux";
import LinearProgressBar from "../LinearProgressBar/LinearProgressBar";
import debounce from 'lodash.debounce';
import { updateUserActiveTime } from "../../utils/translationUtil";
import { useParams } from "react-router-dom";
function GlossaryList({
  handleCloseGlossary,
  chapter,
  glossaryFilteredData,
  handleChangeSection,
  glossaryLoadingState,
  setBookGlossaryData,
  bookGlossaryData,
  handleSearchChange,
  glossarySearchInput,
  openedGlossaryData,
  setOpenedGlossaryData,
  findWordInSourceEditor,
  sourceEditor,
  findGlossaryData,
  findOverBookData,
  resetAccordianValues,
  updateFindTextResults,
  handleChapterChange,
  fetchTranslatedChapterPending,
  setIsFirstOccurence,
  isFirstOccurence,
  translateEditor,
  removeGlossaryChangesFromTranslatedChapter,
  handleApplyGlossary,
  handleFixGlossary
}) {
  const [screenType, setScreenType] = useState("glossaryList");
  const [disableApplyButton, setDisableApplyButton] = useState(false);
  const {glossaryAppliedData}=useSelector((state)=>state.editor)
  const [applicationProgress, setApplicationProgress] = useState({});
  const { loggedInUserEmail} = useSelector((state) => state.main);
  const [updateIterationData, setUpdateIterationData] = useState({
    chapterNo: "",
    iterationNo: "",
  });
  const params=useParams()
  const [showLoader, setShowLoader] = useState(false);
  useEffect(() => {}, []);


  const debouncedUpdateUserActiveTime = useCallback(
    debounce((translationId,loggedInUserEmail) =>{
      try {
        return updateUserActiveTime(translationId,loggedInUserEmail)
      } catch (error) {}
      }, 1000),
    []
  );
  
  const updateLastActiveTimeOnScroll=()=>{
    debouncedUpdateUserActiveTime(params.id,loggedInUserEmail)
  }
  useEffect(() => {
    if (
      chapter &&
      findOverBookData?.englishWord &&
      !fetchTranslatedChapterPending
    ) {
      const finalEnglishWord = findOverBookData?.englishWord;
      if (!updateIterationData.chapterNo) {
        console.log(
          "debug not required",
          findOverBookData.englishWord,
          isFirstOccurence,
          updateIterationData
        );
        findWordInSourceEditor({
          operation: "find",
          englishText: finalEnglishWord,
          isFirstOccurence: isFirstOccurence,
        });
      }

      if (updateIterationData.chapterNo) {
        const findAndReplaceEditing = sourceEditor.plugins.get(
          "FindAndReplaceEditing"
        );
        findWordInSourceEditor({
          operation: "find",
          englishText:finalEnglishWord,
          occurrenceNo: findAndReplaceEditing?.state?.results?.get(
            updateIterationData.iterationNo - 1 ?? 0
          ),
        });
        setUpdateIterationData({ chapterNo: "", iterationNo: "" });
        return;
      }
    }
    const glossaryListContainer = document.querySelector(".glossaryList");
    glossaryListContainer?.addEventListener(
      "scroll",
      updateLastActiveTimeOnScroll
    );
    return () =>
      glossaryListContainer?.removeEventListener(
        "scroll",
        updateLastActiveTimeOnScroll
      );
  }, [findOverBookData, fetchTranslatedChapterPending]);



  useEffect(() => {
    if(Object.keys(glossaryAppliedData).length){
      document.documentElement.style.setProperty(
        `--glossary-highlight-transparent-bg`,
        "#3B37FF33"
      );
      
    }else{
      document.documentElement.style.setProperty(
        `--glossary-highlight-transparent-bg`,
        "transparent"
      );
      removeGlossaryChangesFromTranslatedChapter()
    }

    document.documentElement.style.setProperty(
      `--glossary-highlight-bg`,
      "#3B37FF33"
    );
  }, [glossaryAppliedData])

  const handleChangeScreenType=()=>{
    setScreenType("applicationStatus")
  }
  

  return (
    <div
      style={{ height: "calc(100dvh - 133px - 71px)" }}
      className={`w-[576px] glossaryContainer shrink-0 top-[100px] left-4  fixed py-2 gap-3 flex  pb-[10px]  flex-col px-3 bg-[#FFFFFF] my-12  rounded-md`}
    >
      {screenType === "glossaryList" ? (
        <>
          <div className="flex justify-between">
            <div className="flex items-center gap-4 ">
              <span className="text-[#1E1C80] font-poppins text-base font-normal leading-normal">
                Glossary Entries
              </span>
            </div>
            <div className="flex items-center gap-3">
              <Button
                variant="contained"
                className="gap-2 text-xs rounded px-3 py-[6px]"
                onClick={() => {
                  handleChangeSection({
                    type: "addGlossary",
                    operationType: "add",
                  });
                }}
              >
                <WhiteAddGlossaryIcon className="text-white" />
                Add to Glossary
              </Button>
              <CloseIcon
                className="cursor-pointer w-5 h-5"
                onClick={()=>{handleCloseGlossary("glossaryList")}}
              />
            </div>
          </div>
          <div className="text-[#1E1C80] font-poppins text-sm mt-1 font-normal leading-normal">
            Total Glossaries ({glossaryFilteredData?.length})
          </div>
          <div
            // style={{ borderBottom: "1px solid #BFBFBF" }}
            className="flex items-center gap-5 w-full"
          >
            <div
              style={{ border: "1px solid #A6A6A6" }}
              className="flex text-[10px] w-full px-2 py-[6px] items-center rounded gap-2"
            >
              <GlossarySearchIcon className="w-4 h-5" />
              <input
                value={glossarySearchInput}
                onChange={handleSearchChange}
                placeholder="Search..."
                type="text"
                maxLength={100}
                className="border-none w-full outline-none"
              />
            </div>
          </div>

          <div
            style={{ height: "calc(100dvh - 133px - 71px - 130px - 55px)" }}
            className=" overflow-y-scroll glossaryList hideSourceScrollBar"
          >
            {
              // bookGlossaryData.length > 0 ?
              glossaryLoadingState ? (
                <div className="w-full text-center text-[#5F6368] text-sm pt-10">
                  Loading...
                </div>
              ) : glossarySearchInput && !glossaryFilteredData?.length ? (
                <div className="w-full justify-center items-center flex-col flex pt-4 gap-2">
                  <p className="text-[#5F6368] text-  [10px] m-0">Opps!</p>

                  <p className="text-[#CF1322] text-[14px] m-0">
                    Searched glossary does not exist
                  </p>

                  <Button
                    onClick={() => {
                      handleChangeSection({
                        type: "addGlossary",
                        selectedEnglishWord: glossarySearchInput,
                        operationType: "add",
                      });
                    }}
                    variant="outlined"
                    className="flex rounded-[4px] text-[12px] py-[6px] px-3 gap-2"
                  >
                    Add it to Glossary <AddGlossaryIcon />
                  </Button>
                </div>
              ) : !glossaryFilteredData?.length ? (
                <div className="w-full text-center text-[#5F6368] text-sm pt-10">
                  Loading...
                </div>
              ) : (
                glossaryFilteredData.map((glossaryItem) => {
                  return (
                    <GlossaryAccordionComponent
                      key={glossaryItem?.id}
                      chapter={chapter}
                      glossaryItem={glossaryItem}
                      openedGlossaryData={openedGlossaryData}
                      setOpenedGlossaryData={setOpenedGlossaryData}
                      handleChangeSection={handleChangeSection}
                      bookGlossaryData={bookGlossaryData}
                      setBookGlossaryData={setBookGlossaryData}
                      findWordInSourceEditor={findWordInSourceEditor}
                      sourceEditor={sourceEditor}
                      resetAccordianValues={resetAccordianValues}
                    />
                  );
                })
              )
            }
          </div>
        </>
      ) : (
        <GlossaryApplicationStatus
          handleCloseGlossaryPannel={handleCloseGlossary}
          findOverBookData={findOverBookData}
          sourceEditor={sourceEditor}
          findGlossaryData={findGlossaryData}
          updateFindTextResults={updateFindTextResults}
          handleChapterChange={handleChapterChange}
          setDisableApplyButton={setDisableApplyButton}
          translateEditor={translateEditor}
          openedGlossaryData={openedGlossaryData}
          setApplicationProgress={setApplicationProgress}
          setShowLoader={setShowLoader}
          showLoader={showLoader}
          chapter={chapter}
          setUpdateIterationData={setUpdateIterationData}
          handleBackButton={()=>{
            setScreenType("glossaryList");
            setDisableApplyButton(false);
            setShowLoader(false);
          }}
          prevScreen={"glossaryList"}
        />
      )}
       {showLoader && (
          <div className="absolute left-0 bottom-[52px] w-full">
            <LinearProgressBar
              value={
                (100 / findGlossaryData?.totalOccurances) *
                applicationProgress.occuranceNo
              }
            />
          </div>
        )}
      <GlossaryFooter
        findGlossaryData={findGlossaryData}
        findWordInSourceEditor={findWordInSourceEditor}
        findOverBookData={findOverBookData}
        openedGlossaryData={openedGlossaryData}
        sectionType={screenType}
        setSectionType={setScreenType}
        showLoader={showLoader}
        applicationProgress={applicationProgress}
        disableApplyButton={disableApplyButton}
        handleFixGlossary={()=>handleFixGlossary(handleChangeScreenType)}
        handleApplyGlossary={handleApplyGlossary}
      />
    </div>
  );
}

export default GlossaryList;
