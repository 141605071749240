import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { Button, CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import { cleanGlossaryApplicationData } from "../../utils/firebaseActions";
import { setGlossaryAppliedData } from "../../App/editor/editor.actions";
import { updateUserActiveTime } from "../../utils/translationUtil";

function GlossaryFooter({
  handleFixGlossary,
  findGlossaryData,
  sectionType,
  setSectionType,
  findWordInSourceEditor,
  findOverBookData,
  openedGlossaryData,
  applicationProgress,
  showLoader,
  disableApplyButton,
  handleApplyGlossary
}) {
  const { isGlossaryApplying, glossaryAppliedData } = useSelector(
    (state) => state.editor
  );
  const { loggedInUserEmail} = useSelector((state) => state.main);
  
  const dispatch=useDispatch()
  const params=useParams()
  return (
    <div
      style={{ boxShadow: "0px -6px 20px 0px rgba(0, 0, 0, 0.05)" }}
      className="footer py-2 flex justify-between items-center right-0 px-3 absolute bottom-0 w-full "
    >
      <div
        onClick={() => {
          if (!findGlossaryData?.totalOccurances) return;
          setSectionType("applicationStatus");
          updateUserActiveTime(params.id,loggedInUserEmail)
        }}
        className="text-sm flex gap-[6px] items-center cursor-pointer text-[#1E1C80] font-[poppins] font-[500]"
      >
        {(sectionType === "glossaryMatches"  || sectionType === "glossaryList") && (
          <>
            {isGlossaryApplying.status &&
            isGlossaryApplying.operationType === "fixAll" ? (
              <span className="underline">
                Applying{" "}
                {findGlossaryData?.totalOccurances > 1 ? "Fixes" : "Fix"} (
                {findGlossaryData?.totalOccurances})
              </span>
            ) : glossaryAppliedData?.[
                findOverBookData?.englishWord
              ] && isGlossaryApplying.operationType === "fixAll" ? (
              <span className="underline">
                Applied{" "}
                {findGlossaryData?.totalOccurances > 1 ? "Fixes" : "Fix"} (
                {findGlossaryData?.totalOccurances})
              </span>
            ) : (
              <span className="underline">
                Recommended{" "}
                {findGlossaryData?.totalOccurances > 1 ? "Fixes" : "Fix"} (
                {findGlossaryData?.totalOccurances})
              </span>
            )}
          </>
        )}
      </div>
      <div className="flex gap-[15px] items-center">
        <Button
          disabled={
            (isGlossaryApplying.status || !findGlossaryData?.totalOccurances)
              && true
              // : findGlossaryData?.currentIteration <= 1
          }
          className="cursor-pointer py-[6px] px-0 gap-1 flex items-center"
          onClick={() => {
            findWordInSourceEditor({operation:"prev"});
          }}
        >
          <ExpandLessRoundedIcon />
          Previous
        </Button>
        <Button
          disabled={
            (isGlossaryApplying.status || !findGlossaryData?.totalOccurances)
              && true
              // : findGlossaryData?.currentIteration ===
              //   findGlossaryData?.totalOccurances
          }
          className="cursor-pointer py-[6px] px-0 gap-1 flex"
          onClick={() => {
            findWordInSourceEditor({operation:"next"});
          }}
        >
          Next
          <ExpandMoreRoundedIcon />
        </Button>
        <Button
          onClick={()=>handleFixGlossary && handleFixGlossary()}
          variant="outlined"
          disabled={
            (isGlossaryApplying?.englishWord ===
              findOverBookData?.englishWord &&
              isGlossaryApplying.status) ||
            isGlossaryApplying.operationType === "fixAll"
              ? true
              : openedGlossaryData.english_word
              ? false
              : true
          }
          className="flex rounded-[4px] relative text-[12px] py-[6px] px-3 gap-2"
        >
          <div className="flex items-center gap-2">
            {isGlossaryApplying.status &&
              isGlossaryApplying.operationType === "fix" && (
                <CircularProgress size="16px" color="inherit" />
              )}
            Fix
          </div>
        </Button>
        <div className="relative">
          <Button
            disabled={
              (isGlossaryApplying?.englishWord ===
                findOverBookData?.englishWord &&
                isGlossaryApplying.status) ||
              isGlossaryApplying.operationType === "fix"
                ? true
                : disableApplyButton
                ? true
                : openedGlossaryData.english_word
                ? false
                : true
            }
            onClick={() => {
              if(!handleApplyGlossary) return
              dispatch(setGlossaryAppliedData({}));
              cleanGlossaryApplicationData(params.id);
              handleApplyGlossary("fixAll");
              setSectionType("applicationStatus");
            }}
            variant="contained"
            className="flex rounded-[4px] fixAll relative text-[12px] py-[6px] px-3 gap-2"
          >
            <div className="flex items-center gap-2">
              {showLoader ? (
                <span>
                  Fix {applicationProgress?.occuranceNo ?? 0} /
                  {findGlossaryData?.totalOccurances ?? 0} 
                </span>
              ) : (
                <span>Fix All</span>
              )}
              {isGlossaryApplying.status &&
                isGlossaryApplying.operationType === "fixAll" && (
                  <CircularProgress size="16px" color="inherit" />
                )}
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default GlossaryFooter;
