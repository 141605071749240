import React, { useState } from "react";
import "./Table.scss";



import { useNavigate, useSearchParams } from "react-router-dom";
import menuIcon from "../../assets/menu-icon-dot.svg";
import Loader from "../Loader/Loader";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import InfoIcon from "@mui/icons-material/Info";
import download from "../../assets/download_icon.svg";
import getDownloadLink from "../../utils/downloadBook";
import { ToastContainer, toast } from "react-toastify";
import {ReactComponent as DeleteIcon} from "../../assets/deleteIcon.svg";
import { deleteBookData } from "../../App/TranslationActions/action";
import useApi from "../../api/useAPi";
import apiUrls from "../../api/apiUrl";
import { Button, Dialog, DialogContent, DialogActions, DialogTitle, TableRow } from "@mui/material"
import { getCurrentTime, checkIsUserActive, checkIsCurrentBookEngaged, getUserNameFromEmail,updateUserActiveTime } from "../../utils/translationUtil";
import { useCookies } from "react-cookie";
import { loggedInUserActivity, userLastActiveTime } from "../../utils/firebaseActions";
import { setUserOpenedBookInfo } from "../../App/DashboardActions/action";
import { ADD_APIS_RESPONSE_STATUS, ADD_EDITING_START_TIME } from "../../utils/fireStoreTypes";

import CustomTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const Table = ({
  data,
  restrictedBookData,
  fetchBooksList,
  isLoading,
  page,
  rowsPerPage,
  isDataFoundInSearch
}) => {
  const navigate = useNavigate();
  const { fetchData } = useApi();
  const [selectedRow, setSelectedRow] = useState(null);

  const { isItemLoadingId, loggedInUserEmail } = useSelector(
    (state) => state.main
  );
  const toggleMenu = (index) => {
    if (selectedRow === index) {
      setSelectedRow(null);
    } else {
      setSelectedRow(index);
    }
  };

  const [isDialogOpen, setDialogOpen] = useState(false);
  const [uniqueString, setUniqueString] = useState("");
  const [selectedBook, setSelectedBook] = useState("");
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();

  const handleOpenDialog = (item) => {
    console.log("linked", item.link);
    setUniqueString(item.link);
    setSelectedBook(item);
    setDialogOpen(true);
  };

  console.log("data", data);
  console.log("uniq", uniqueString);
  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleDeleteConfirm = () => {
    handleDeleteBook();
    // setDialogOpen(false);
  };

  const handleDeleteBook = async () => {
    //TODO: Confirmation has to be asked if the book has to be deleted?
    const toastId = toast.loading("Deleting the Book...");
    try {
      setLoader(true);
      console.log("url to delete", apiUrls.deleteBookData + uniqueString);
      const response = await fetchData(
        apiUrls.deleteBookData + uniqueString,
        "DELETE"
      );
      if (response) {
        toast.update(toastId, {
          render: "Book deleted successfully!",
          type: "success",
          isLoading: false,
          autoClose: 5000,
        });

        // loggedInUserActivity({
        //   activity: ADD_APIS_RESPONSE_STATUS,
        //   apiResponse: {
        //     status: "success",
        //     apiEndPoint: apiUrls.deleteBookData + uniqueString,
        //     translationId: uniqueString ?? "null",
        //     responseMessage: response?.status ?? "Book deleted successfully",
        //   },
        // });
        // return response
      }
    } catch (error) {
      console.log("Unique String Error", error);
      toast.update(toastId, {
        render: "Failed to delete the Book!",
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
      // loggedInUserActivity({
      //   activity: ADD_APIS_RESPONSE_STATUS,
      //   apiResponse: {
      //     status: "error",
      //     apiEndPoint: apiUrls.deleteBookData,
      //     translationId: uniqueString ?? "null",
      //     responseMessage: error?.message ?? "error in deleting book",
      //   },
      // });
    } finally {
      setLoader(false);
      setDialogOpen(false);
    }
  };

  // const updateUserActiveTime =async(translationId)=>{
  //   const lastActiveTime=await getCurrentTime()
  //   if(lastActiveTime && loggedInUserEmail){
  //     await userLastActiveTime(translationId,"update",loggedInUserEmail,lastActiveTime)
  //   }
  // }

  const handleViewTranslation = async (item) => {
    if (!item) return;
    const { link: translationId, title: bookTitle } = item;
    let isThisBookEngaged = false;
    let currentBookOccupiedUser = "";
    if (restrictedBookData[translationId] && loggedInUserEmail) {
      const activeUsersList = restrictedBookData[translationId];
      const currentTime = await getCurrentTime();
      activeUsersList.forEach((user) => {
        console.log(
          "its activeUsersList :::",
          loggedInUserEmail,
          user.user,
          checkIsUserActive(currentTime, user?.lastActiveTime)
        );
        if (
          checkIsUserActive(currentTime, user?.lastActiveTime) &&
          user.user !== loggedInUserEmail
        ) {
          isThisBookEngaged = true;
          currentBookOccupiedUser = getUserNameFromEmail(user?.user);
        }
      });
    }

    if (isThisBookEngaged) {
      toast(
        `Please wait until ${currentBookOccupiedUser} has finished working on the book`,
        {
          type: "warning",
          autoClose: 3000,
        }
      );
    } else {
      dispatch(setUserOpenedBookInfo(item));
      updateUserActiveTime(translationId, loggedInUserEmail);
      loggedInUserActivity({
        activity: ADD_EDITING_START_TIME,
        openedBookTitle: bookTitle,
        translationId: translationId,
      });

      const lastOpenedChapterNo = restrictedBookData?.bookMarkData
        ? restrictedBookData?.bookMarkData[translationId]?.lastOpenedChapterNo
        : undefined;
      if (lastOpenedChapterNo) {
        console.log("lastOpenedChapterNo", lastOpenedChapterNo);
        navigate(
          `/review-translation/${translationId}?chapter=${lastOpenedChapterNo}`
        );
      }

      navigate(`/review-translation/${translationId}`);
    }
  };

  return (
    <>
      <ToastContainer hideProgressBar={true} />
      <div className="mt-[106px] md:mt-[83px] h-[calc(100dvh-106px-45px)] md:h-[calc(100dvh-83px-45px)] p-4 pb-6 w-full overflow-auto">
        {isLoading ? (
          <Loader />
        ) : (
          <>
         { isDataFoundInSearch ? <TableContainer className="h-[calc(100vh-170px)] overflow-auto border-[1px] border-solid border-[#1E1C80] rounded-md" style={{scrollbarWidth: "none"}}>
         <CustomTable className="table rounded-md">
              <TableHead className="sticky z-50 top-0  bg-[#FFF]">
                <TableRow className="sticky top-[0.5px]  bg-[#FFF]">
                  <TableCell className="rounded-tl-md">#</TableCell>
                  <TableCell style={{ textAlign: "left", paddingLeft: "50px" }}>
                    Title
                  </TableCell>
                  <TableCell style={{ textAlign: "left", paddingLeft: "50px" }}>
                    Author
                  </TableCell>
                  <TableCell style={{ textAlign: "left" }}>Status</TableCell>
                  <TableCell style={{ textAlign: "left", paddingLeft: "50px" }}>
                    Created
                  </TableCell>
                  <TableCell className="rounded-tr-md" style={{ textAlign: "left", paddingLeft: "25px" }}>
                    Actions
                  </TableCell>
                  {/* <th>Pages</th> */}
                  {/* <th style={{ textAlign: "left" }}>Actions</th> */}
                </TableRow>
              </TableHead>
              {<TableBody >
                {data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                      <Tooltip
                        title={item?.title?.length > 35 ? item?.title : ""}
                      >
                        <TableCell
                          data-label="Title: "
                          className="bookTitle"
                          style={{ textAlign: "left", paddingLeft: "50px"}}
                        >
                          {item.title.length > 35
                            ? item.title.substring(0, 35) + "..."
                            : item.title}
                        </TableCell>
                      </Tooltip>
                      <TableCell
                        data-label="Author: "
                        className="bookAuthor"
                        style={{ textAlign: "left", paddingLeft: "50px" }}
                      >
                        {item.author.length > 35
                          ? item.author.substring(0, 35) + "..."
                          : item.author}
                      </TableCell>
                      <TableCell
                        style={{
                          // display: "flex",
                          // justifyContent: "left",
                          // alignItems: "center",
                          textAlign: "left",
                        }}
                        data-label="Status: "
                      >
                        {isItemLoadingId && isItemLoadingId === index ? (
                          <Box sx={{ display: "flex" }}>
                            <CircularProgress />
                          </Box>
                        ) : (
                          item.status
                        )}
                      </TableCell>
                      <TableCell
                        data-label="Created: "
                        style={{ textAlign: "left", paddingLeft: "50px" }}
                      >{`${item.created_at_date} ${item.created_at_time}`}</TableCell>
                      {/* <td>{item.pages}</td> */}
                      <TableCell
                        style={{
                          position: "relative",
                          display: "flex",
                          justifyContent: "left",
                          alignItems: "center",
                        }}
                        data-label=""
                      >
                        {isItemLoadingId && isItemLoadingId === index ? (
                          <Box sx={{ display: "flex" }}>
                            <CircularProgress />
                          </Box>
                        ) : item.status === "AI Translation Completed" ? (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Button
                              style={{
                                // background:
                                //   item.status !== "AI Translation Completed"
                                //     ? "#9695b1"
                                //     : "#3b37ff",
                                // padding:
                                //   item.status !== "AI Translation Completed"
                                //     ? "6px 12px"
                                //     : "6px 25px",
                              }}
                              className="view-btn"
                              onClick={() => handleViewTranslation(item)}
                              disabled={
                                item.status !== "AI Translation Completed"
                              }
                            >
                              {item.status !== "AI Translation Completed"
                                ? "Awaiting Translation"
                                : "View Translation"}
                            </Button>
                            {/* <Tooltip title="Download Book">
                            <img
                              src={download}
                              alt="Download"
                              className="download-icon-dashboard"
                              onClick={() => {
                                getDownloadLink(item.link, "translated", item?.assistant_name, item?.temperature)
                              }}
                        />
                          </Tooltip> */}
                            <IconButton
                              style={{
                                color: "red",
                                marginLeft: "8px",
                                // color:'#3b37ff'
                              }}
                              onClick={() => handleOpenDialog(item)}
                            >
                              <Tooltip title="Delete book">
                                <DeleteIcon />
                              </Tooltip>
                            </IconButton>
                          </div>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div className="await-button">
                              Awaiting Translation
                            </div>
                            <Tooltip
                              title={
                                <span style={{ fontSize: "12px" }}>
                                  Please wait as the AI works its magic to
                                  translate the book for you.
                                </span>
                              }
                            >
                              <IconButton
                                onClick={() => {
                                  fetchBooksList(index);
                                }}
                              >
                                <InfoIcon sx={{ color: "#3b37ff" }} />
                              </IconButton>
                            </Tooltip>
                          </div>
                        )}
                        {/* <div
                    style={{
                      marginLeft: "0rem",
                      width: "2rem",
                      height: "2rem",
                    }}
                    onClick={() => {
                      toggleMenu(index);
                    }}
                  >
                    <img src={menuIcon} alt="menu-icon" className="menu-icon" />
                  </div> */}

                        {/* {selectedRow === index && (
                    <div
                      onClick={() =>
                        navigate(
                          "/pairsPage?pairedBookId=" +
                            item.link +
                            "&&pageNumber=1"
                        )
                      }
                      className="menu"
                    >
                      View Pairing
                    </div>
                  )} */}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>}
            </CustomTable>
            </TableContainer>:<div className="w-full border-[1px] border-solid border-[#1E1C80] rounded-md h-[60vh] flex flex-col">
               <div className="flex items-center justify-between border-b-[1px] border-t-0 border-x-0 border-solid border-[#1E1C80]">
                   <div className="p-[14px] w-[20%] dummyTd" style={{ textAlign: "left", paddingLeft: "50px" }}>#</div>
                   <div className="p-[14px] w-[20%] dummyTd" style={{ textAlign: "left", paddingLeft: "50px" }}>Title</div>
                   <div className="p-[14px] w-[20%] dummyTd" style={{ textAlign: "left", paddingLeft: "50px" }}>Author</div>
                   <div className="p-[14px] w-[20%] dummyTd" style={{ textAlign: "left", paddingLeft: "50px" }}>Created</div>
                   <div className="p-[14px] w-[20%] dummyTd" style={{ textAlign: "left", paddingLeft: "50px" }}>Actions</div> 
               </div>
               <div className="flex justify-center h-full text-lg items-center">
                        No Data Found
               </div>
               </div>}
            <Dialog
              open={isDialogOpen}
              onClose={handleCloseDialog}
              sx={{ minWidth: "200px" }}
            >
              <DialogTitle>
                {loader ? `Deleting Book` : `Delete Book`}
              </DialogTitle>
              <DialogContent>
                <p>
                  Are you sure you want to delete{" "}
                  <strong>{selectedBook.title}</strong> book by{" "}
                  <strong>{selectedBook.author}</strong>?
                </p>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "16px",
                  }}
                >
                  <Button disabled={loader} onClick={handleCloseDialog}>
                    Cancel
                  </Button>
                  <Button
                    disabled={loader}
                    onClick={handleDeleteConfirm}
                    color="error"
                  >
                    Delete
                  </Button>
                </Box>
              </DialogContent>
            </Dialog>
          </>
        )}
      </div>
    </>
  );
};




export default Table;
