import React, { useCallback, useEffect, useState } from "react";
import { ReactComponent as LeftChevron } from "../../assets/leftChevron.svg";
import CloseIcon from "@mui/icons-material/Close";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { ReactComponent as GlossaryViewIcon } from "../../assets/glossaryViewIcon.svg";

import debounce from 'lodash.debounce';
import "./GlossaryApplicationStatus.scss";
import store from "../../App/store";
import { useSelector } from "react-redux";
import GlossaryApplicationCard from "../GlossaryApplicationCard/GlossaryApplicationCard";
import { useParams } from "react-router-dom";
import { updateUserActiveTime } from "../../utils/translationUtil";
function GlossaryApplicationStatus({
  highlightedEnglishText,
  handleCloseGlossaryPannel,
  findOverBookData,
  sourceEditor,
  findGlossaryData,
  updateFindTextResults,
  handleChapterChange,
  setDisableApplyButton,
  translateEditor,
  openedGlossaryData,
  setApplicationProgress,
  showLoader,
  setShowLoader,
  chapter,
  setUpdateIterationData,
  handleBackButton,
  prevScreen
}) {
  const { glossaryAppliedData, isGlossaryApplying ,currentChapter} = useSelector(
    (state) => state.editor
  );
  const [occurrencesListData, setOccurrencesListData] = useState({});
  const [glossaryApplicationStatus, setGlossaryApplicationStatus] = useState(
    {}
  );
  const [replaceTranslationLoader, setReplaceTranslationLoader] = useState(false)
  const [expanded, setExpanded] = useState(Object?.keys(findOverBookData?.wordOccurrencesOverBook)); 
  const params=useParams()
  const { loggedInUserEmail} = useSelector((state) => state.main);

  const handleRedirection = (iteration, chapterNo) => {
    let currentChapterNo = store.getState().editor.currentChapter;
    const findAndReplaceEditing = sourceEditor.plugins.get(
      "FindAndReplaceEditing"
    );

    if (currentChapterNo === chapterNo) {
      if (findGlossaryData.currentIteration === getOccuranceNo(Number(chapterNo),iteration)) return;
      findAndReplaceEditing?.state?.set(
        "highlightedResult",
        findAndReplaceEditing?.state?.results?.get(iteration - 1 ?? 0)
      );
      updateFindTextResults(findOverBookData.englishWord);
      return;
    }

    handleChapterChange(chapterNo);
    setUpdateIterationData({ chapterNo: chapterNo, iterationNo: iteration });
  };


  useEffect(() => {
    const getHighlightedTextSentence = () => {
      let chapterWiseOccuranceData = findOverBookData?.chapterWiseOccurancePara;
      if (!chapterWiseOccuranceData) return;
      // const allTranslatedParagraphs = document.querySelectorAll(
      //   `#source .ck-editor__editable > p`
      // );
      const finalData = {};
      let occuranceNo = 0;
      Object.keys(chapterWiseOccuranceData).forEach((chapterNo) => {
        let resultTexts = [];
        let textContainsParagraph =
          chapterWiseOccuranceData?.[chapterNo]?.English || [];
        textContainsParagraph?.forEach((paragraph) => {
          // Get the full rendered text of the paragraph
          const parser = new DOMParser();
          const paragraphElement = parser.parseFromString(
            paragraph,
            "text/html"
          );
          const fullText = paragraphElement?.body?.textContent || "";

          const sentences = fullText.match(/[^.!?…—]*(?:[.!?…—]+|$)/g);

          sentences?.forEach((sentence) => {
            // Regex to find all occurrences of the word in the current sentence
            const regex = new RegExp(
              `\\b${findOverBookData?.englishWord || highlightedEnglishText}\\b`,
              "gi"
              // `(?<!\\w)${findOverBookData?.englishWord || highlightedEnglishText}(?!\\w)`,
              // "gi"
            );
  
            const matches = [...sentence.matchAll(regex)];
  
            // For each occurrence, create a new highlighted sentence
            matches.forEach((match) => {
              let highlightedSentence = sentence.replace(
                regex,
                (m, i) => (i === match.index ? `<span class="highlightText">${m}</span>` : m)
              );
  
              occuranceNo++;
              resultTexts.push({
                occuranceNo: occuranceNo,
                englishText: highlightedSentence,
                latestTranslatedText: "",
              });
            });
          });
        });
        finalData[chapterNo] = resultTexts;
      });
      setOccurrencesListData(finalData);
    };

    getHighlightedTextSentence();
  }, [findOverBookData]);

  const getOccuranceStatus = (occuranceStatus, aiStatus) => {
    let finalStatus="";
    
    switch(aiStatus){
      case "applied":
        return "Applied"
      case "fix_not_applied":
        return "Skipped"
      case "ai_suggested":
        return "AiSuggested"
    }

    switch (occuranceStatus) {
      case "applying":
        return "Applying";
      case "pending":
        return "Pending";
      case "skipped":
        return "Skipped";
      case "applied":
        return "Applied";
      default :
        return "NotApplied"
    }
  };


   const getChapterApplicationStatus = (applicationStatus) => {
    let chapterCount=0;
    Object.keys(applicationStatus).forEach((chapterNo)=>{
      if (!applicationStatus[chapterNo] || applicationStatus?.[chapterNo]?.[1] === "NotApplied") return;
      let status="Applied"
      Object?.keys(applicationStatus?.[chapterNo])?.forEach((occuranceNo) => {
        if (applicationStatus?.[chapterNo]?.[occuranceNo] === "Pending" || applicationStatus?.[chapterNo]?.[occuranceNo] === "Applying")
          status= "Pending";
      });

      if(status==="Applied")
        chapterCount++;
    })

    return chapterCount;
  
  };

  useEffect(() => {
    if (isGlossaryApplying.status) {
      if(isGlossaryApplying.operationType==="fixAll"){
        setShowLoader(true);
        setDisableApplyButton(true);
      }
    }

    let applicationStatus = {};
    let currentGlossaryData =
      glossaryAppliedData?.[
        findOverBookData?.englishWord || highlightedEnglishText
      ] || {};
    let occuranceNo = 0;
    Object?.keys(findOverBookData?.wordOccurrencesOverBook)?.forEach(
      (chapterNo,i) => {
        let occurencesData = {};
        for (
          let i = 1;
          i <= findOverBookData?.wordOccurrencesOverBook[chapterNo];
          i++
        ) {
          occuranceNo++;
          if(isGlossaryApplying.operationType==="fixAll" && occuranceNo===1 && isGlossaryApplying.status){
            occurencesData[occuranceNo]="Applying"
          }else if(isGlossaryApplying.occurrenceNo===occuranceNo){
            occurencesData[occuranceNo]="Applying"
          }else{
            occurencesData[occuranceNo] = isGlossaryApplying.status
            ? "Pending"
            : currentGlossaryData?.status === "Applied"
            ? "Applied"
            : "NotApplied";
          }
          
        }
        applicationStatus[chapterNo] = occurencesData;
      }
    );

    if (Object?.keys(currentGlossaryData)?.length) {
      if (
        currentGlossaryData?.status === "Applying"
        // && isGlossaryApplying.status
      ) {
      
        let currentOccuranceNo = 0;
        Object.keys(currentGlossaryData?.affectedChaptersData || {})?.forEach(
          (occuranceNo) => {
            let changedParagraphData =
              currentGlossaryData?.affectedChaptersData[occuranceNo];
            if (!changedParagraphData.status) return;
            // affectedChaptersData.forEach((changedParagraphData)=>{
            if (
              applicationStatus?.[changedParagraphData?.chapter_number]?.[
                occuranceNo
              ]
            ) {
              applicationStatus[changedParagraphData?.chapter_number][
                occuranceNo
              ] = 
              getOccuranceStatus(
                changedParagraphData?.status,
                changedParagraphData?.ai_status
              );

              if(applicationStatus[changedParagraphData?.chapter_number][
                occuranceNo
              ]==="Applied" || applicationStatus[changedParagraphData?.chapter_number][
                occuranceNo
              ]==="Skipped" || applicationStatus[changedParagraphData?.chapter_number][
                occuranceNo
              ]==="AiSuggested"){
                currentOccuranceNo=occuranceNo
              }
            }
            setOccurrencesListData((prev) => {
              // Create a shallow copy of the prev state to avoid mutation
              let updatedOccurancesDetails = { ...prev }; // Shallow copy
              
              let updateCurrentChapter = updatedOccurancesDetails?.[changedParagraphData?.chapter_number]?.map(
                (chapterOccurance, i) => {
            
                  // Only update the specific occurance
                  if (Number(occuranceNo) === chapterOccurance?.occuranceNo) {
                    chapterOccurance = { ...chapterOccurance }; // Create a new copy of chapterOccurance
            
                    // Update the latestTranslatedText
                    chapterOccurance.latestTranslatedText = '';
                    changedParagraphData?.after_sentence?.forEach((glossaryAppliedSentence) => {
                      chapterOccurance.latestTranslatedText += `${glossaryAppliedSentence} <br/>`;
                    });
                    if(!chapterOccurance?.latestTranslatedText.trim() && chapterOccurance.status==="applied")
                      chapterOccurance.latestTranslatedText='none'

                  }
            
                  return chapterOccurance; // Return the updated occurance
                }
              ) || [];
            
              // Return a new object with updated chapter data
              return {
                ...prev, // Copy the previous state
                [changedParagraphData?.chapter_number]: updateCurrentChapter, // Update only the chapter
              };
            });
          }
        );
        let chapterCount = getChapterApplicationStatus(applicationStatus)
          setApplicationProgress({
            occuranceNo: currentOccuranceNo,
            chapterNo: chapterCount,
          });
      }
      
    }else{
      setApplicationProgress({
        occuranceNo: 0,
        chapterNo: 0,
      })
    }

    setGlossaryApplicationStatus(applicationStatus);
    
  }, [glossaryAppliedData, isGlossaryApplying]);



  useEffect(() => {
     if(!isGlossaryApplying.status && showLoader){
      setTimeout(()=>{
        setShowLoader(false)
      },2000)
     }
  }, [isGlossaryApplying.status])
  
 

  const getOccuranceNo = (chapterNo, occuranceNo) => {
    let finalOccuranceNo = 0;
    const keys =
      Object.keys(findOverBookData?.wordOccurrencesOverBook || {})?.sort(
        (a, b) => a - b
      ) || [];
    for (let i = 0; i < keys.length; i++) {
      const chapter = keys[i];
      if (chapter < chapterNo) {
        finalOccuranceNo += findOverBookData.wordOccurrencesOverBook[chapter];
      }
    }
    return finalOccuranceNo + occuranceNo;
  };

  const handleChange = (panel) => (event, isExpanded) => {
    if (isExpanded) {
      // Add the panel to the expanded state if not already present
      setExpanded((prev) => [...prev, panel]);
    } else {
      // Remove the panel from the expanded state
      setExpanded((prev) => prev.filter((item) => item !== panel));
    }
  };

  
  const debouncedUpdateUserActiveTime = useCallback(
    debounce((translationId,loggedInUserEmail) =>{
      try {
        return updateUserActiveTime(translationId,loggedInUserEmail)
      } catch (error) {}
      }, 1000),
    []
  );
  
  const updateLastActiveTimeOnScroll=()=>{
    debouncedUpdateUserActiveTime(params.id,loggedInUserEmail)
  }
  
  useEffect(() => {
    const glossaryListContainer = document.querySelector(".occurrencesContainer");
    glossaryListContainer?.addEventListener(
      "scroll",
      updateLastActiveTimeOnScroll
    );
    return () =>
      glossaryListContainer?.removeEventListener(
        "scroll",
        updateLastActiveTimeOnScroll
      );
  }, [])
  



  return (
    <div className="flex flex-col gap-2">
      <div className="flex justify-between">
        <div className="flex items-center gap-2">
          <LeftChevron
            className="cursor-pointer"
            onClick={() => {
              if(replaceTranslationLoader) return
              handleBackButton()
            }}
          />{" "}
          {(isGlossaryApplying.status && isGlossaryApplying.operationType==="fixAll") ? (
            <span className="text-base text-[#1E1C80]">
              Applying {findGlossaryData?.totalOccurances>1?"fixes":"fix"} for “
              {findOverBookData?.englishWord || highlightedEnglishText}”{" "}
            </span>
          ) : (glossaryAppliedData?.[
              findOverBookData?.englishWord || highlightedEnglishText
            ] &&  isGlossaryApplying.operationType==="fixAll") ? (
            <span className="text-base text-[#1E1C80]">
              Applied {findGlossaryData?.totalOccurances>1?"fixes":"fix"} for “
              {findOverBookData?.englishWord || highlightedEnglishText}”{" "}
            </span>
          ) : (
            <span className="text-base text-[#1E1C80]">
              ({findGlossaryData.totalOccurances}) Recommended {findGlossaryData?.totalOccurances>1?"Fixes":"Fix"} for "
              {findOverBookData?.englishWord || highlightedEnglishText}"
            </span>
          )}
        </div>
        <div>
          <CloseIcon
            className="cursor-pointer"
            onClick={() => {
              if(isGlossaryApplying.status || replaceTranslationLoader) return 
               handleCloseGlossaryPannel(prevScreen);
              setUpdateIterationData({ chapterNo: "", iterationNo: "" });
              setDisableApplyButton(false);
              setShowLoader(false);
            }}
          />
        </div>
      </div>

      <div
        style={{
          height:
            isGlossaryApplying.status ||
            glossaryAppliedData?.[
              findOverBookData?.englishWord || highlightedEnglishText
            ]
              ? "calc(100dvh - 133px - 180px)"
              : "calc(100dvh - 133px - 172px)",
        }}
        className="flex flex-col occurrencesContainer px-2 pb-1 hideSourceScrollBar overflow-y-scroll text-sm "
      >
        {findOverBookData?.wordOccurrencesOverBook &&
          Object?.keys(findOverBookData?.wordOccurrencesOverBook).map(
            (chapterKey) => {
              return (
                <Accordion
                  key={chapterKey}
                  expanded={expanded.includes(chapterKey)}
                  onChange={handleChange(chapterKey)}
                  sx={{
                    borderBottomLeftRadius: "0",
                    "&:last-of-type": {
                      borderRadius: "0px",
                    },
                    "&::before": {
                      height: 0, // Removes the height
                    },
                    boxShadow: "none",
                    // borderBottom: "1px solid #BFBFBF",
                    "& .MuiAccordion-region": { height: "100%" },
                    "& .MuiAccordionSummary-root": {
                      // Targeting the accordion summary
                      "&.Mui-expanded": {
                        // Adjust min-height when expanded
                        minHeight: "auto",
                      },
                    },

                    "& .MuiPaper-root": {
                      // Targeting the accordion summary
                      "&.Mui-expanded": {
                        // Adjust min-height when expanded
                        margin: "0px",
                      },
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<GlossaryViewIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{
                      display: "flex",
                      borderBottomLeftRadius: "0",
                      paddingBottom: "0",
                      minHeight: "30px",
                      height: "45px",
                      paddingLeft: "5px",
                    }}
                  >
                    <div className="flex items-center gap-2 w-full">
                      <div className="flex justify-between  w-full items-center ">
                        <span className="text-sm font-[500] w-[50%] text-[#1E1C80]">
                          CHAPTER {chapterKey}
                        </span>

                        <span className="text-[12px] pr-2 text-[#000000]">
                          {
                            findOverBookData?.wordOccurrencesOverBook?.[
                              chapterKey
                            ]
                          }{" "}
                          {findOverBookData?.wordOccurrencesOverBook?.[
                            chapterKey
                          ] === 1
                            ? "fix"
                            : "fixes"}
                        </span>
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      padding: "0",
                      border: "none",
                      boxShadow: "none",
                      borderBottomLeftRadius: "0",
                      height: "100%",
                    }}
                  >
                    <div className="flex items-center px-2 h-full gap-2">
                      <div className="flex flex-col w-full  pt-2  gap-4">
                        {occurrencesListData[chapterKey]?.length > 0 &&
                          occurrencesListData[chapterKey]?.map(
                            (iteration, i) => {
                              return (
                                <GlossaryApplicationCard
                                  chapterKey={chapterKey}
                                  iterationNo={i}
                                  applicationData={{
                                    ...iteration,
                                    status:
                                      glossaryApplicationStatus[chapterKey][
                                        iteration.occuranceNo
                                      ],
                                  }}
                                  openedGlossaryData={openedGlossaryData}
                                  findGlossaryData={findGlossaryData}
                                  findOverBookData={findOverBookData}
                                  replaceTranslationLoader={replaceTranslationLoader}
                                  setReplaceTranslationLoader={setReplaceTranslationLoader}
                                  chapter={chapter}
                                  handleRedirection={handleRedirection}
                                  sourceEditor={sourceEditor}
                                  translateEditor={translateEditor}
                                />
                              );
                            }
                          )}
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              );
            }
          )}
      </div>
    </div>
  );
}

export default GlossaryApplicationStatus;
