export const SET_CURRENT_CHAPTER ='actions/SET_CURRENT_CHAPTER';
export const SET_FETCH_TRANSLATED_CHAPTER_PENDING='actions/SET_FETCH_TRANSLATED_CHAPTER_PENDING'
export const SET_IS_TRANSLATION_CONTENT_EDITED="actions/SET_IS_TRANSLATION_CONTENT_EDITED"
export const SET_FIND_AND_REPLACE_WORD_MATCHES_COUNT="actions/SET_FIND_AND_REPLACE_WORD_MATCHES_COUNT"
export const SET_IS_GLOSSARY_APPLYING="actions/SET_IS_GLOSSARY_APPLYING"
export const SET_GLOSSARY_SUGGESTION_DATA="actions/SET_GLOSSARY_SUGGESTION_DATA"
export const SET_GLOSSARY_LIST="actions/SET_GLOSSARY_LIST"
export const SET_GLOSSARY_APPLIED_DATA="actions/SET_GLOSSARY_APPLIED_DATA"
export const SET_GLOSSARY_SCREEN_TYPE="actions/SET_GLOSSARY_SCREEN_TYPE"
export const SET_PRE_APPLIED_GLOSSARY_DATA="actions/SET_PRE_APPLIED_GLOSSARY_DATA"


export const setCurrentChapter = (chapter) => {
  return {
    type: SET_CURRENT_CHAPTER,
    payload: chapter
  }
}


export const setFetchTranslatedChapterPending = (payload) => {
  return {
    type: SET_FETCH_TRANSLATED_CHAPTER_PENDING,
    payload: payload
  }
}

export const setIsTranslationContentEdited = (payload) => {
  return {
    type: SET_IS_TRANSLATION_CONTENT_EDITED,
    payload: payload
  }
}

export const setFindAndReplaceWordMatchesCount = (payload) => {
  return {
    type: SET_FIND_AND_REPLACE_WORD_MATCHES_COUNT,
    payload: payload
  }
}

export const setIsGlossaryApplying = (payload) => {
  return {
    type: SET_IS_GLOSSARY_APPLYING,
    payload: payload
  }
}

export const setGlossarySuggestionsData = (payload) => {
  return {
    type: SET_GLOSSARY_SUGGESTION_DATA,
    payload: payload
  }
}

export const setGlossaryList = (payload) => {
  return {
    type: SET_GLOSSARY_LIST,
    payload: payload
  }
}


export const setGlossaryAppliedData = (payload) => {
  return {
    type: SET_GLOSSARY_APPLIED_DATA,
    payload: payload
  }
}

export const setGlossaryScreenType = (payload) => {
  return {
    type: SET_GLOSSARY_SCREEN_TYPE,
    payload: payload
  }
}


export const setPreAppliedGlossaryData = (payload) => {
  return {
    type: SET_PRE_APPLIED_GLOSSARY_DATA,
    payload: payload
  }
}