import React, { useState, useMemo, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import GlossarySummaryCard from './GlossarySummaryCard';
import GlossaryWarningModal from "../glosssaryWarningModal/GlossaryWarningModal";
import { ReactComponent as AddGlossaryIcon } from "../../assets/addGlossaryIcon.svg";
import { updateGlossarytoTheBook, deleteGlossaryFromTheBook, clearFindResults } from '../../utils/glossaryUtils';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

const GlossaryAccordionComponent = ({...props}) => {
    const [ deleteWarningData, setDeleteWarningData ] = useState(null);
    const {isGlossaryApplying}=useSelector((state)=>state.editor)
    const [expanded, setExpanded] = useState(false);
    const [ variantToDelete, setVariantToDelete ] = useState('');
    const { english_word, dutch_word_array, id } = props?.glossaryItem;
    const { openedGlossaryData, 
        setOpenedGlossaryData, 
        bookGlossaryData,
        setBookGlossaryData,
        handleChangeSection,
        findWordInSourceEditor,
        resetAccordianValues,
    chapter } = props ?? {};

    const bookInfo = {
        author: chapter?.book_info?.author_name,
        language: chapter?.translation_info?.translated_to,
        genre: chapter?.book_info?.genre,
    };


    useEffect(() => {
        if (openedGlossaryData?.english_word === english_word) {
          setExpanded(true);
        } else {
          setExpanded(false);
        //   setSelectedGlossary({ englishWord: "", variant: "" });
        }
      }, [openedGlossaryData]);

    const handleChange = () => {
       if( isGlossaryApplying.status) return ;
       
        if (expanded) {
          resetAccordianValues();
        } else {
         setOpenedGlossaryData(props?.glossaryItem);
        }
        setExpanded(!expanded);
      };
    

    const isGlossaryExist = useMemo(()=>{
        const object = bookGlossaryData?.find(
          (glossary) => glossary?.english_word?.toLowerCase() === english_word?.toLowerCase()
        )
        return object
    },[bookGlossaryData])

    const handleLocalStateUpdate = (variant) => {
        setBookGlossaryData(prevItems =>
            prevItems.map(item =>
              item.id === id
                ? {
                    ...item,
                    dutch_word_array: item.dutch_word_array.filter(
                      dutchWordObj =>
                        dutchWordObj.replacement_dutch_word !== variant
                    ),
                  }
                : item
            )
          );
    }
    
    const handleDeleteGlossary = async () => {
        try{
            await deleteGlossaryFromTheBook(id);
            setBookGlossaryData((prev)=>prev?.filter((elem)=>elem?.id !== id));
            setDeleteWarningData(null);
            resetAccordianValues();
        }
        catch(err){
            toast.error('Unable to delete the glossary')
        }
    };

    const handleDeleteVariant = async () => {
        if(deleteWarningData?.type === 'Last Variant'){
            handleDeleteGlossary();
            return
        }
        const dutchWordVariant = isGlossaryExist?.dutch_word_array?.filter((elem)=>elem?.replacement_dutch_word !== variantToDelete);
        const payload = {
            ...bookInfo,
            english_word: english_word,
            dutch_word_array: dutchWordVariant,
            glossary_item_scope: "Book",
            application_scope: "book_level",
            chapter_numbers: [],
        }
        try{
            await updateGlossarytoTheBook(payload,id);
            handleLocalStateUpdate(variantToDelete);
            setDeleteWarningData(null);
        }
        catch(err){
            toast.error('Unable to delete variant')
        }
    }


    useEffect(() => {
        if (expanded) {
          findWordInSourceEditor({operation:"find",englishText:english_word})
        } 
      }, [expanded]);

    return (
        <div>
            {deleteWarningData && (
                <GlossaryWarningModal
                    deleteWarningData={deleteWarningData}
                    closePopup={() => setDeleteWarningData(null)}
                    handleDelete={handleDeleteVariant}
                />
            )}
            <Accordion
                onChange={handleChange} 
                expanded={expanded}
                sx={{
                    borderBottomLeftRadius: "0",
                    borderBottomRightRadius: "0",
                    "&:last-of-type": {
                        borderRadius: "0px",
                    },
                    "&::before": {
                        height: 0,
                    },
                    boxShadow: "none",
                    borderBottom: "1px solid #BFBFBF",
                    "& .MuiAccordion-region": { height: "100%" },
                }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <div className="flex justify-between w-full border border-green-500 align-middle">
                        <div style={{fontFamily: "Times New Roman"}} className='text-[18px] font-normal lowercase'>
                            {english_word}
                        </div>
                        <span className='text-xs font-normal mr-3 mt-2'>
                            {dutch_word_array?.length} {dutch_word_array?.length>1?"variants":"variant"}
                        </span>
                    </div>
                </AccordionSummary>
                <AccordionDetails sx={{ marginTop: 0, paddingTop: "4px" }}>
                    <div className='flex bg-[#F8F9FC] rounded-[4px] flex-col px-3 py-2 gap-2'>
                        <div className="text-sm flex justify-between items-center px-[2px] ">
                            <span className="text-[#1E1C80]">
                                {dutch_word_array?.length <= 1
                                    ? "Dutch Variant"
                                    : "Dutch Variants"}
                            </span>
                            <div onClick={()=> handleChangeSection({type:"addGlossary",selectedEnglishWord:english_word,operationType:"add"})} className="text-[12px] text-[#3B37FF] cursor-pointer flex items-center justify-start gap-1">
                                <AddGlossaryIcon className="text-[#3B37FF]" />
                                Add a variant
                            </div>
                        </div>
                        {dutch_word_array?.map((elem,index) => (
                            <GlossarySummaryCard 
                                key={index} 
                                description={elem} 
                                chapter={chapter}
                                handleEditGlossary={(variant)=>{
                                    handleChangeSection({type:"addGlossary",selectedEnglishWord:"",operationType:"edit",glossaryToEdit:{
                                        id:id,
                                        english_word:english_word,
                                        variant:variant
                                    }})
                                }}
                                handleDeleteVariant={(variant)=>{
                                    setVariantToDelete(variant);
                                    const type = dutch_word_array?.length === 1 ? 'Last Variant' : 'Variant'
                                    setDeleteWarningData({
                                        type: type,
                                        variant: variant,
                                    });
                                }}
                                glossaryItem={props?.glossaryItem}
                            />
                        ))}
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default GlossaryAccordionComponent;
