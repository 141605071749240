import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { ReactComponent as GlossaryViewIcon } from "../../assets/glossaryViewIcon.svg";
import ViewReasonAccordian from "../ViewReasonAccordian/ViewReasonAccordian";
import { ReactComponent as AddGlossaryIcon } from "../../assets/addGlossaryIcon.svg";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import { cn } from "../../lib/utils";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Tooltip } from "@mui/material";
import GlossaryApplicationCard from "../GlossaryApplicationCard/GlossaryApplicationCard";
import { object } from "prop-types";
import {
  getChapterNoFromString,
  scrollOccurrenceContainer,
  updateGlossaryPreAppliedData,
  updatePredefinedClassName,
} from "../../utils/glossaryUtils";
import { scrollToHighlightedText } from "../../utils/translationUtil";
import { useSaveTranslatedChapter } from "../../hooks/useReviewTranslation";
import { setPreAppliedGlossaryData } from "../../App/editor/editor.actions";
import { useParams } from "react-router-dom";
function PreAppliedGlossaryReview({
  selectedCardId,
  chapter,
  handleCloseGlossaryPannel,
  translateEditor,
  handleChapterChange,
  fetchTranslatedChapterPending,
  setTranslatedContent,
  setIsLayoutFixed,
}) {
  const successHandler = (data) => null;
  const {
    data,
    mutate: saveEditedTranslatedChapter,
    error,
    isPending,
    isSuccess,
  } = useSaveTranslatedChapter({ successHandler });
  const dispatch = useDispatch();
  const params = useParams();
  const [isApproved, setIsApproved] = useState("");
  const [replaceTranslationLoader, setReplaceTranslationLoader] =
    useState(false);
  const { glossaryList, preAppliedGlossaryData } = useSelector(
    (state) => state.editor
  );
  const [preAppliedDataCount, setPreAppliedDataCount] = useState({
    high: 0,
    medium: 0,
    low: 0,
    total: 0,
  });
  const [selectedCard, setSelectedCard] = useState({
    cardId: "",
    chapterString: "",
    status: "",
  });

  const [expanded, setExpanded] = useState(Object.keys(preAppliedGlossaryData || {})); // Track the currently expanded chapter
  
 
  useEffect(() => {
      if (Object.keys(preAppliedGlossaryData || {})?.length) {
        setExpanded(Object.keys(preAppliedGlossaryData || {}));
        calculatePreAppliedGlossaryData();
        scrollOccurrenceContainer(`${selectedCardId}`);
        setSelectedCard({
          cardId: selectedCardId,
          chapterString: `chapter_${chapter.chapter_number}`,
        });
      }
  }, [preAppliedGlossaryData]);


  // const extractReasonFromSentence = () => {
  //   if (!affectedTranslatedSentence) return;
  //   const parser = new DOMParser();
  //   const parsedSentenceElement = parser?.parseFromString(
  //     `<div>${affectedTranslatedSentence}</div>`,
  //     "text/html"
  //   );
  //   const skippedElement = parsedSentenceElement?.querySelector(
  //     ".skipped[data-replacement-reason]"
  //   );
  //   const addedElement = parsedSentenceElement?.querySelector(
  //     ".added[data-replacement-reason]"
  //   );
  //   let englishWord = addedElement?.getAttribute("glossary_english_word");
  //   let variantUsed = addedElement?.getAttribute("variant_used");
  //   variantUsed = variantUsed
  //     ? variantUsed
  //     : skippedElement?.getAttribute("variant_used");
  //   englishWord = englishWord
  //     ? englishWord
  //     : skippedElement?.getAttribute("glossary_english_word");
  //   if (!englishWord?.trim()) return;

  //   const selectedGlossaryItem = glossaryList?.find(
  //     (glossary) =>
  //       glossary?.english_word?.toLowerCase() ===
  //       englishWord?.trim()?.toLowerCase()
  //   );
  //   if (selectedGlossaryItem) setGlossaryItem(selectedGlossaryItem);
  //   if (variantUsed)
  //     setSelectedGlossary({ englishWord: englishWord, variant: variantUsed });
  // };

  // useEffect(() => {
  //   extractReasonFromSentence();
  // }, [affectedTranslatedSentence]);

  const calculatePreAppliedGlossaryData = () => {
    let totalCards = 0;
    let low = 0;
    let high = 0;
    let medium = 0;
    Object.keys(preAppliedGlossaryData).forEach((chapterString) => {
      const applicationData = preAppliedGlossaryData[chapterString];
      Object.keys(applicationData).forEach((cardId) => {
        totalCards++;
        if (applicationData[cardId].priority === "high") high++;

        if (applicationData[cardId].priority === "medium") medium++;

        if (applicationData[cardId].priority === "low") low++;
      });
    });
    setPreAppliedDataCount({ high, low, medium, total: totalCards });
  };


  const getGlossaryItem = (englishWord) => {
    return glossaryList?.find(
      (glossary) =>
        glossary?.english_word?.toLowerCase() ===
        englishWord?.trim()?.toLowerCase()
    );
  };

  const getNextCardId = (preApppliedGlossaryData, approvedCardId) => {
    let nextCardData = { cardId: "", chapterString: "" };
    let gotCurrentId = false;
    Object.keys(preApppliedGlossaryData).forEach((chapterString, index) => {
      const applicationData = preApppliedGlossaryData[chapterString];
      Object.keys(applicationData).forEach((cardId, i) => {
        if (gotCurrentId) {
          nextCardData = {
            cardId,
            chapterString,
          };
          gotCurrentId = false;
        }
        if (cardId === approvedCardId) gotCurrentId = true;
      });
    });

    console.log("nextCardData", nextCardData, preApppliedGlossaryData);
    if (nextCardData.cardId) return nextCardData;

    return {};
  };

  const addAnimation = (cardId) => {
    const escapedClassName = CSS.escape(cardId);
    let preDefinedGlossary = document.querySelector(
      `[local_id=${escapedClassName}]`
    );
    preDefinedGlossary?.classList?.add("greenBackground");
    preDefinedGlossary?.classList?.remove("predefined-added");
    setTimeout(() => {
      preDefinedGlossary?.classList?.remove("greenBackground");
      preDefinedGlossary?.classList?.add("predefined-added");
    }, 2000);
    // preDefinedGlossary.addEventListener('animationend', () => {
    //   preDefinedGlossary.classList.remove('shake');
    // }, { once: true });
  };

  useEffect(() => {
    if (fetchTranslatedChapterPending || !selectedCard?.cardId) return;
    scrollToHighlightedText("translate", false, selectedCard?.cardId);
    addAnimation(selectedCard?.cardId);
    if (selectedCard.status === "approve") {
      handleApproveGlossary(selectedCard?.cardId, selectedCard?.chapterString);
      setSelectedCard({ cardId: "", chapterString: "", status: "" });
    }
  }, [fetchTranslatedChapterPending]);

  const handleApproveGlossary = async (cardId, chapterString) => {
    try {
      if (getChapterNoFromString(chapterString) !== chapter.chapter_number) {
        handleRedirection(cardId, chapterString, "approve");
        return;
      }
      if (!cardId || !chapterString || isApproved?.trim()) return;
      setIsApproved(cardId);
      const oldData = { ...preAppliedGlossaryData };
       const nextCardData=getNextCardId(oldData,cardId)
      const updatedData = await updateGlossaryPreAppliedData(
        {
          unique_string: params.id,
          local_id: cardId,
          status: "approved",
          chapter_id: chapterString,
        },
        preAppliedGlossaryData
      );
      
       if(nextCardData.cardId){
         handleRedirection(nextCardData.cardId,nextCardData.chapterString)
       }
      dispatch(setPreAppliedGlossaryData(updatedData));
      let editedTranslation = translateEditor.getData();
      editedTranslation = updatePredefinedClassName(editedTranslation, cardId);
      const store = translateEditor.config.get("store").getState();
      setTranslatedContent(editedTranslation);
      setIsLayoutFixed(false);
      saveEditedTranslatedChapter({
        translation_id: params.id,
        chapter: store.editor.currentChapter,
        translated_data: editedTranslation,
      });
      setIsApproved("");
    } catch (error) {}
  };

  const handleRedirection = (cardId, chapterString, type) => {
    if (!cardId || !chapterString) return;
    try {
      let chapterNo = getChapterNoFromString(chapterString);
      if (chapterNo === chapter.chapter_number) {
        if (selectedCard?.cardId === cardId) return;
        scrollToHighlightedText("translate", false, cardId);
        addAnimation(cardId);
      } else {
        handleChapterChange(chapterNo);
      }
      setSelectedCard({ cardId, chapterString, status: type });
    } catch (error) {}
  };

  const handleChange = (panel) => (event, isExpanded) => {
    if (isExpanded) {
      // Add the panel to the expanded state if not already present
      setExpanded((prev) => [...prev, panel]);
    } else {
      // Remove the panel from the expanded state
      setExpanded((prev) => prev.filter((item) => item !== panel));
    }
  };

  return (
    <div
      style={{ height: "calc(100dvh - 133px - 71px)" }}
      className={`w-[576px] shrink-0 top-[100px] left-4 glossaryContainer fixed  gap-2 flex 
            "pb-[67.46px]" 
           flex-col px-3 bg-[#FFFFFF] my-12  rounded-md`}
    >
      <div className="flex flex-col gap-2">
        <div className="flex justify-between p-2">
          <span className="text-[#1E1C80]  ">
            Review pre-applied glossaries ({preAppliedDataCount.total})
          </span>
          <div>
            <CloseIcon
              className="cursor-pointer w-5 h-5"
              onClick={() => {
                handleCloseGlossaryPannel("preAppliedGlossaryReview");
              }}
            />
          </div>
        </div>

        {Object.keys(preAppliedGlossaryData || {}).length> 0 ?<div
          style={{
            height: "calc(100dvh - 133px - 130px)",
          }}
          className="flex flex-col occurrencesContainer px-2  hideSourceScrollBar overflow-y-scroll text-sm "
        >
          {Object.keys(preAppliedGlossaryData || {}).map((chapterString) => {
            const applicationData = preAppliedGlossaryData[chapterString];
            return Object.keys(applicationData || object).length > 0  && (
              <Accordion
                expanded={expanded?.includes(chapterString)}
                onChange={handleChange(chapterString)}
                // value={chapterString}
                sx={{
                  borderBottomLeftRadius: "0",
                  "&:last-of-type": {
                    borderRadius: "0px",
                  },
                  "&::before": {
                    height: 0, // Removes the height
                  },
                  boxShadow: "none",
                  // borderBottom: "1px solid #BFBFBF",
                  "& .MuiAccordion-region": { height: "100%" },
                  "& .MuiAccordionSummary-root": {
                    // Targeting the accordion summary
                    "&.Mui-expanded": {
                      // Adjust min-height when expanded
                      minHeight: "auto",
                    },
                  },

                  "& .MuiPaper-root": {
                    // Targeting the accordion summary
                    "&.Mui-expanded": {
                      // Adjust min-height when expanded
                      margin: "0px",
                    },
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<GlossaryViewIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  sx={{
                    display: "flex",
                    borderBottomLeftRadius: "0",
                    paddingBottom: "0",
                    minHeight: "30px",
                    height: "45px",
                    paddingLeft: "5px",
                  }}
                >
                  <div className="flex items-center gap-2 w-full">
                    <div className="flex justify-between  w-full items-center ">
                      <span className="text-sm font-[500] w-[50%] text-[#1E1C80]">
                        CHAPTER {getChapterNoFromString(chapterString) || 1}
                      </span>
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    padding: "0",
                    border: "none",
                    boxShadow: "none",
                    borderBottomLeftRadius: "0",
                    height: "100%",
                  }}
                >
                  <div className="flex flex-col gap-5">
                    {Object.keys(applicationData || {}).map((dataId, i) => {
                      const cardData = applicationData[dataId];
                      return (
                        <GlossaryApplicationCard
                          chapterKey={applicationData.chapterNo}
                          iterationNo={i}
                          applicationData={{
                            englishText: cardData?.english_sentence,
                            latestTranslatedText: cardData?.dutch_sentence,
                            occurrenceNo: dataId,
                            reason: cardData?.data_replacement_reason,
                            variantUsed: cardData?.variant_used,
                            cardId: dataId,
                            chapterString: chapterString,
                            selectedCardId: selectedCard?.cardId,
                            englishPara: cardData?.english_paragraph,
                            isApproved: isApproved,
                          }}
                          openedGlossaryData={getGlossaryItem(
                            cardData?.glossary_english_word
                          )}
                          //  findGlossaryData={findGlossaryData}
                          //  findOverBookData={findOverBookData}
                          replaceTranslationLoader={replaceTranslationLoader}
                          setReplaceTranslationLoader={
                            setReplaceTranslationLoader
                          }
                          chapter={chapter}
                          screen="preAppliedGlossaryReview"
                          handleRedirection={handleRedirection}
                          //  sourceEditor={sourceEditor}
                          translateEditor={translateEditor}
                          handleApproveGlossary={handleApproveGlossary}
                        />
                      );
                    })}
                  </div>
                </AccordionDetails>
              </Accordion>
            )
          })}
        </div>:<div
          style={{
            height: "calc(100dvh - 133px - 130px)",
          }} className="flex pt-52 justify-center text-[#999898] text-sm">
            No pre-applied glossary to review
          </div>}
      </div>
    </div>
  );
}

export default PreAppliedGlossaryReview;
